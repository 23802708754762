import React from 'react'
import { graphql, Link } from 'gatsby'
import styles from './ctn.module.sass'

import Card from '../components/card'

import Layout from '../components/layout'
import SEO from '../components/seo'

const CTNPage = ({ data }) => (
   <Layout>
      <SEO title="Current Trends &amp; News" />
      <div className={['page', styles.page__ctn].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Current Trends &amp; News
            </h1>
            <p className={['page--subheader', styles.page__subheader].join('')}>
               A weekly financial recap curated by SPC's team of wealth
               management and tax-integrated advisors.
               <Link to="/legal/disclaimers#tax">*</Link>
               <br />
               We monitor and explore the intricacies of the financial world and
               share insights into market developments.
            </p>
         </div>
         <div className={styles.ctn__posts}>
            {data.allContentfulBlogPost &&
               data.allContentfulBlogPost.edges
                  .reverse()
                  .map(({ node }, index) => (
                     <Card
                        key={index}
                        title={node.title}
                        meta={
                           'By ' +
                           (node.author ? node.author : 'SPC Financial, Inc.') +
                           ' • ' +
                           node.content.childMarkdownRemark.timeToRead +
                           ' min'
                        }
                        content={node.previewText}
                        actionTarget="_self"
                        actionLink={'/ctn/' + node.slug}
                        actionText="Read More"
                     />
                  ))}
         </div>
      </div>
   </Layout>
)

export default CTNPage

export const query = graphql`
   query {
      allContentfulBlogPost(
         filter: { node_locale: { eq: "en-US" } }
         sort: { fields: [createdAt], order: ASC }
      ) {
         edges {
            node {
               slug
               title
               author
               previewText
               content {
                  childMarkdownRemark {
                     timeToRead
                  }
               }
            }
         }
      }
   }
`
